import React from "react";

import "./styles.css";

function Header() {
  return (
    <thead>
      <tr className="container-header">
        <th width="10%">Token</th>
        <th width="10%">Assinatura</th>
        <th width="10%">Date</th>
        <th width="60%">Médico</th>
        <th width="10%">Download</th>
      </tr>
    </thead>
  );
}

export default Header;
