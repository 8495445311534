import React from "react";
import Icon from "../Icon";
import Spinner from "../Spinner";

import "./styles.css";

function Button({ loading, handleClick, label, children, icon, ...rest }) {
  const onClick = async (evt) => {
    evt.stopPropagation();

    await handleClick();
  };

  return (
    <button
      onClick={onClick}
      className="btn-default"
      disabled={loading}
      {...rest}
    >
      {!loading && icon && <Icon icon={icon} alt="icone download" />}
      {loading && <Spinner />}

      {<p>{children}</p>}
    </button>
  );
}

export default Button;
