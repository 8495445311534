import React, { useState } from "react";
import { formatDate } from "../../utils/formatDate";
import Button from "../Button";
import icnDownload from "../../assets/icons/icn-download.png";
import icnCheckGreen from "../../assets/icons/icn-check-green.svg";
import Icon from "../Icon";

import "./styles.css";
import { useMedicalHistory } from "../../context";

function Row({ prescription }) {
  const {
    token,
    date,
    signed,
    uuid: idPrescription,
    practitionerName,
  } = prescription;

  const { downloadFile } = useMedicalHistory();

  const [loading, setLoading] = useState(false);

  const download = async () => {
    setLoading(true);

    const file = await downloadFile(token, idPrescription);
    const fileName = `prescricao-${token}`;

    setLoading(false);

    handleFile(file, fileName);
  };

  const handleFile = async (file, fileName) => {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file, fileName);

      return;
    }

    const anchor = document.createElement("a");
    anchor.href = URL.createObjectURL(file);
    anchor.download = fileName;

    document.body.appendChild(anchor);

    anchor.click();

    document.body.removeChild(anchor);
  };

  return (
    <>
      <td>{token}</td>
      <td>
        {signed ? (
          <Icon icon={icnCheckGreen} size={24} alt="icone de assinatura" />
        ) : (
          <></>
        )}
      </td>
      <td>{formatDate(date)}</td>
      <td>{practitionerName}</td>
      <td>
        <Button handleClick={download} icon={icnDownload} loading={loading} />
      </td>
    </>
  );
}

export default Row;
