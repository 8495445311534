import React from "react";

function Icon({ icon = "", size = "20", ...rest }) {
  return (
    <img
      src={icon}
      style={{ width: `${size}px`, height: `${size}px`, margin: "4px" }}
      alt="icone"
      {...rest}
    />
  );
}

export default Icon;
