import React from "react";

import Home from "./pages/Home";
import { MedicalHistoryProvider } from "./context";

import "./styles.css";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <MedicalHistoryProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Home />
    </MedicalHistoryProvider>
  );
}

export default App;
