import React from "react";

import "./styles.css";

function Container({ children, ...rest }) {
  return (
    <div className="container-wrapper">
      <div className="content-wrapper" {...rest}>
        {children}
      </div>
    </div>
  );
}

export default Container;
