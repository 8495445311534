import React, {
  useState,
  useMemo,
  useContext,
  createContext,
  useCallback,
} from "react";
import { toast } from "react-toastify";
import api from "../service/api";

const MedicalHistoryContext = createContext();

const MedicalHistoryProvider = ({ children }) => {
  const [data, setData] = useState({
    patientName: "",
    prescriptions: null,
    loading: false,
    success: true,
  });
  const [pagination, setPagination] = useState({});

  const getPrescriptions = useCallback((nextPage) => {
    setData((prevState) => ({ ...prevState, loading: true }));

    const params = window.location.search;
    const QUERY_PARAMS = new URLSearchParams(params).toString();
    const urlPrescriptions = `partner/prescription/history?${QUERY_PARAMS}`;
    const url = nextPage ? nextPage : urlPrescriptions;

    api
      .get(url)
      .then((response) => handlerResponse(response, nextPage))
      .catch((error) => {
        console.error(error);

        setData((prevState) => ({ ...prevState, success: false }));

        toast.error(
          "Não foi possível carregar as prescrições do paciente. Tente novamente."
        );
      });
  }, []);

  const handlerResponse = (response, nextPage) => {
    const { data: dataResponse, links } = response?.data || {};

    setPagination(links);

    const prescriptions = dataResponse?.map((prescription) => ({
      ...prescription.attributes,
      id: prescription.id,
    }));
    const patientName = prescriptions?.[0].patientName;

    if (nextPage) {
      return setData((prevState) => ({
        ...prevState,
        loading: false,
        prescriptions: [
          ...new Set([...prevState?.prescriptions, ...prescriptions]),
        ],
      }));
    }

    setData({
      patientName,
      prescriptions,
      loading: false,
    });
  };

  const downloadFile = (token, idPrescription) => {
    const pdfUrl = `prescricoes/${token}/pdf?document=${idPrescription}`;

    return api
      .get(pdfUrl, {
        responseType: "blob",
      })
      .then((resp) => {
        if (resp?.data) {
          return new Blob([resp?.data], { type: "application/pdf" });
        }
      })
      .catch((error) => {
        console.error({ error });

        toast.error("Não foi possível fazer o download. Tente novamente.");
      });
  };

  const value = useMemo(
    () => ({
      downloadFile,
      getPrescriptions,
      data,
      pagination,
    }),
    [data, getPrescriptions, pagination]
  );

  return (
    <MedicalHistoryContext.Provider value={value}>
      {children}
    </MedicalHistoryContext.Provider>
  );
};

const useMedicalHistory = () => {
  const context = useContext(MedicalHistoryContext);
  if (context === undefined) {
    throw new Error(
      "useMedicalHistory can only be used inside MedicalHistoryProvider"
    );
  }
  return context;
};

export { MedicalHistoryProvider, useMedicalHistory };
