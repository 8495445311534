import React, { useEffect, useRef } from "react";
import Button from "../../components/Button";
import Header from "../../components/Header";
import ListItem from "../../components/ListItem";
import { useMedicalHistory } from "../../context";
import Container from "../../components/Container";
import Spinner from "../../components/Spinner";
import { useWindowSize } from "../../hooks/useWindowResize";

import "./styles.css";

function Home() {
  const { data, pagination, getPrescriptions } = useMedicalHistory();
  const { width } = useWindowSize();

  const { patientName, prescriptions, loading, success } = data;

  const hasNextPage = pagination?.next;

  const MAX_WIDTH = "532";

  const mounted = useRef(true);

  const handleNextPage = async () => {
    await getPrescriptions(pagination.next);
  };

  useEffect(() => {
    if (mounted.current) {
      mounted.current = false;

      getPrescriptions();
    }
  }, [getPrescriptions]);

  if (loading && success)
    return (
      <Container style={{ padding: "40px" }}>
        <Spinner />
      </Container>
    );

  return (
    <Container>
      {patientName && (
        <div className="content-name">
          <span>Prescrições do paciente</span>
          <span>{patientName}</span>
        </div>
      )}

      {!prescriptions && <p id="empty-list">Sem prescrições</p>}

      {prescriptions && (
        <table>
          {width > MAX_WIDTH && <Header />}

          <tbody>
            {prescriptions.map((prescription) => {
              const { token } = prescription;

              return <ListItem key={token} prescription={prescription} />;
            })}
          </tbody>
        </table>
      )}

      {hasNextPage && (
        <Button
          id="btn-load-more"
          loading={loading}
          handleClick={handleNextPage}
        >
          Mais prescrições
        </Button>
      )}
    </Container>
  );
}

export default Home;
