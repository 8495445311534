import React, { useState, useRef } from "react";
import Row from "./Row";
import Card from "./Card";
import { useWindowSize } from "../../hooks/useWindowResize";
import "./styles.css";

function ListItem({ prescription }) {
  const { token, items } = prescription;

  const { width } = useWindowSize();

  const expanderBody = useRef(null);

  const MAX_WIDTH = "532";

  const [onToggle, setOnToggle] = useState(false);

  return (
    <>
      <tr className="list-item" onClick={() => setOnToggle(!onToggle)}>
        {width > MAX_WIDTH ? (
          <Row prescription={prescription} />
        ) : (
          <Card prescription={prescription} />
        )}
      </tr>

      {onToggle && (
        <tr className="expandable">
          <td colSpan={4}>
            <div ref={expanderBody} className="inner">
              {items?.map((item, index) => {
                const { posology, quantity, name } = item;
                const posologyEdit =
                  quantity > 0 ? `${quantity} - ${name}` : name;

                return (
                  <div key={`item-${token}-${index}`}>
                    <p className="item-name">{posologyEdit}</p>
                    <p className="item-posology">{posology}</p>
                  </div>
                );
              })}
            </div>
          </td>
        </tr>
      )}
    </>
  );
}

export default ListItem;
